<template>
  <vs-card>
    <div v-if="labelVisibility" slot="header">
      <h3> Disposal Record </h3>
      <span class="text-base text-green-dark font-medium">{{ this.clinic ? this.clinic.name : "" }}</span>
    </div>

    <form autocomplete="off" class="drugbookFields">
      <div class="flex flex-col space-y-4">
          <div class="w-full">
            <label class="text-md">Date discarded</label>
            <Datepicker
              format="dd MMMM yyyy"
              v-model="drugbookDisposalData.dateDisposed"
              placeholder="Select Date"
            />
          </div>
          <div class="w-full">
            <label for="timeDisposed" class="text-md"> Time Discarded </label>
            <vs-input
              type="time"
              name="timeDisposed"
              data-vv-as="Time Disposed"
              class="w-full"
              v-model="drugbookDisposalData.timeDisposed"
            />
          </div>
          <div class="w-full">
            <label for="drug" class="text-md"> Drug Discarded </label>
            <vs-select
              :disabled="!isProductLoaded"
              ref="disposeSelect"
              class="select relative"
              width="100%"
              placeholder="Select Drug"
              :danger="errors.first('drug') ? true : false"
              :success="
                !errors.first('drug') && drugbookDisposalData.drug !== ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              v-validate="{ required: true }"
              data-vv-as="drug"
              name="drug"
              autocomplete
              :danger-text="errors.first('drug')"
              v-model="drugbookDisposalData.drug"
            >
            <div :key="index" v-for="(item, index) in filteredProducts">
              <vs-select-group :title="item.title" v-if="item.group">
                <vs-select-item
                  :key="index"
                  :value="item.value"
                  :text="item.text"
                  v-for="(item, index) in item.group"
                />
              </vs-select-group>
            </div>
              <!-- <vs-select-item
                :key="index"
                :value="item._id"
                :text="item.name"
                v-for="(item, index) in drugItems"
              /> -->
              <vs-button
              v-if="isProductLoaded"
              class="absolute w-full right-0 py-2 rounded-t-none rounded-b-sm justify-start pl-3 z-10"
              :class="isCustomProduct ? 'hidden' : 'customButton'"
              color="#e1e8e6"
              text-color="#475c55"
              icon="add"
              @click="addCustomProduct"
            >
              Add new Product
            </vs-button>
            </vs-select>
          </div>
          <div
            v-if="drugbookDisposalData.drug === 'add'"
            class="w-full"
          >
            <vs-input
              :danger="errors.first('customProductName') ? true : false"
              :danger-text="errors.first('customProductName')"
              :success="
                !errors.first('customProductName') && customProductName != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              v-validate="'required'"
              data-vv-as="customProductName"
              name="customProductName"
              label="New Product Name"
              placeholder="New Product Name"
              v-model="customProductName"
              class="w-full"
            />
          </div>
          <div
            v-if="drugbookDisposalData.drug === 'add'"
            class="w-full"
          >
            <vs-input
              :danger="errors.first('customProductUnit') ? true : false"
              :danger-text="errors.first('customProductUnit')"
              :success="
                !errors.first('customProductUnit') && customProductUnit != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              v-validate="'required'"
              data-vv-as="customProductUnit"
              name="customProductUnit"
              label="Units of Measurement"
              placeholder="Units of Measurement"
              v-model="customProductUnit"
              class="w-full"
            />
          </div>
          <div class="w-full">
            <label for="discardAmount" class="text-md"> Amount Discarded </label>
            <vs-input
              :danger="errors.first('discardAmount') ? true : false"
              :success="
                !errors.first('discardAmount') &&
                drugbookDisposalData.discardAmount !== ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              data-vv-as="discardAmount"
              name="discardAmount"
              v-validate="{
                required: true,
                min_value: 0.1,
              }"
              autocomplete
              placeholder="Enter Discard Amount"
              :danger-text="errors.first('discardAmount')"
              v-model.number="formattedAmount"
              :disabled="!drugbookDisposalData.drug"
              class="w-full"
              icon-no-border
            />
          </div>
          <div class="w-full">
            <label for="source" class="text-md"> Source </label>
            <vs-select
              :disabled="isNZRegion"
              autocomplete
              class="select"
              width="100%"
              name="authorisedBy"
              v-model="authorisedBy"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.name"
                v-for="(item, index) in sourceData"
              />
              <!-- @input-change="handleSuburbSearch($event.target.value)" -->
            </vs-select>
          </div>
          <!-- <div v-if="authorisedBy === 'other'" class="w-full fieldContainer">
            <div class="w-full">
              <vs-input
                data-vv-as="totalPrice"
                name="totalPrice"
                label="Total Price (Optional)"
                placeholder="Total Price"
                v-model.number="totalPrice"
                class="w-full"
              />
            </div>
          </div> -->
          <div class="w-full" v-if="authorisedBy === 'other'">
            <label for="currentCustomPrescriber" class="text-md"> Prescriber </label>
            <vs-select
              ref="customPrescriber"
              class="select"
              width="100%"
              name="currentCustomPrescriber"
              autocomplete
              v-model="currentCustomPrescriber"
              v-validate="'required'"
              data-vv-as="currentCustomPrescriber"
              :danger="errors.first('currentCustomPrescriber') ? true : false"
              :danger-text="errors.first('currentCustomPrescriber')"
              :success="!errors.first('currentCustomPrescriber') && currentCustomPrescriber != ''"
              val-icon-success="done"
              val-icon-danger="error"
              :class="{'is-danger': errors.has('currentCustomPrescriber')}"
            >
              <div :key="index" v-for="(item, index) in customPrescribers">
                <vs-select-group :title="item.title" v-if="item.group">
                  <vs-select-item
                    :key="index"
                    :value="item.value"
                    :text="item.text"
                    v-for="(item, index) in item.group"
                  />
                </vs-select-group>
              </div>
              <vs-button
                class="absolute w-full right-0 py-2 rounded-t-none rounded-b-sm justify-start pl-3 z-10"
                :class="isAddCustomPrescriber ? 'hidden' : 'bottom-0'"
                color="#e1e8e6"
                text-color="#475c55"
                icon="add"
                @click="addCustomPrescriber"
              >
                Add Custom Prescriber
              </vs-button>
              <!-- <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.name"
                v-for="(item, index) in customPrescribers"
              /> -->
            </vs-select>
          </div>
          <div v-if="currentCustomPrescriber === 'custom'" class="w-full">
            <vs-input
              :danger="errors.first('customPrescriber') ? true : false"
              :danger-text="errors.first('customPrescriber')"
              :success="
                !errors.first('customPrescriber') && customPrescriber != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              name="customPrescriber"
              label="Authorising Dr"
              placeholder="Authorising Dr"
              v-model="customPrescriber"
              class="w-full"
            />
          </div>
          <div class="w-full">
            <label for="discardReason" class="text-md"> Notes (Optional)</label>
            <vs-input
              data-vv-as="discardReason"
              name="discardReason"
              autocomplete
              placeholder="Reason for discard"
              class="w-full"
              v-model="drugbookDisposalData.discardReason"
              data-vv-scope="drugbookDisposalData"
              :danger="errors.first('drugbookDisposalData.discardReason') ? true : false"
              :danger-text="errors.first('drugbookDisposalData.discardReason')"
              :success="!errors.first('drugbookDisposalData.discardReason') && drugbookDisposalData.discardReason != ''"
              val-icon-success="done"
              val-icon-danger="error"
            />
          </div>
          <div class="w-full">
            <label for="batchNumber" class="text-md"> Batch Number </label>
            <vs-input
              autocomplete
              placeholder="Batch Number"
              name="batchNumber"
              class="w-full"
              v-model="drugbookDisposalData.batchNumber"
              v-validate="'required'"
              data-vv-as="batchNumber"
              data-vv-scope="drugbookDisposalData"
              :danger="errors.first('drugbookDisposalData.batchNumber') ? true : false"
              :danger-text="errors.first('drugbookDisposalData.batchNumber')"
              :success="!errors.first('drugbookDisposalData.batchNumber') && drugbookDisposalData.batchNumber != ''"
              val-icon-success="done"
              val-icon-danger="error"
            />
          </div>
          <div class="w-full">
            <label class="text-md">Batch expiry - optional</label>
            <Datepicker
              format="dd MMMM yyyy"
              v-model="drugbookDisposalData.batchExpiry"
              placeholder="Batch Expiry (Optional)"
              :typeable="true"
            >
            </Datepicker>
          </div>
          <div class="w-full">
            <vs-input
              label="Performed by"
              name="performedBy"
              v-model="userFullName"
              disabled
              class="w-full"
            />
          </div>
          <div class="w-full mb-4">
            <p class="font-medium text-base mt-4 mb-2">Declaration</p>
            <div class="flex items-center space-x-1">
              <vs-checkbox
                name="declaration"
                data-vv-as="declaration"
                v-validate="'required'"
                v-model="drugbookDisposalData.declaration"
                >
              </vs-checkbox>
              <label for="declaration" class="text-base">
                I declare that
                <span class="font-medium">{{
                  customProductDeclaration ?
                  `${drugbookDisposalData.discardAmount} ${customProductUnit ? customProductUnit : '--'}` :
                  drugbookDisposalData.discardAmount && drugDetail
                    ? drugbookDisposalData.discardAmount + " " + drugDetail.unit
                    : "--"
                }}</span>
                of
                <span class="font-medium">{{
                  customProductDeclaration ?
                  (customProductName ? customProductName : '--') :
                  drugbookDisposalData.drug && drugDetail ? drugDetail.name : "--"
                }}</span>
                was discarded
              </label>
            </div>
            <span
              v-if="this.declarationChecker"
              class="block span-text-validation-danger vs-input--text-validation-span ml-1 mt-2"
              >{{ this.declarationChecker }}</span
            >
          </div>

          <div class="flex items-center justify-between space-x-4 mt-5">
            <vs-button type="border" class="w-full flex-1 p-3" @click="closeModal">
              Cancel
            </vs-button>
            <vs-button type="border" class="bg-primary hover:bg-primary text-white w-full flex-1 p-3" @click="discardRecord" :disabled="isLoading">Save</vs-button>
          </div>

      </div>
    </form>

  </vs-card>
</template>

<script>
import _ from "lodash";
import Datepicker from "vuejs-datepicker";
import { Validator } from "vee-validate";
import { mapActions, mapMutations } from "vuex";
import moment from "moment";
import jwt_decode from 'jwt-decode';

const dict = {
  custom: {
    drug: {
      required: "Please select a drug",
    },
    discardAmount: {
      required: "Please enter an amount",
      min_value: "Please enter a valid amount",
    },
    discardReason: {
      required: "Notes is required"
    },
    batchNumber: {
      required: "Batch number is required"
    }
  },
};
Validator.localize("en", dict);
export default {
  props: [
    "clinic",
    "drugData",
    "labelVisibility",
    "show",
    "fetchCustomProductss",
    "fetchDrugEntries"
  ],
  components: {
    Datepicker,
  },
  data: () => ({
    userId: "",
    userFullName: "",
    declarationChecker: "",
    drugDetail: {},
    drugItems: [],
    drugbookDisposalData: {
      dateDisposed: moment().format("L"),
      timeDisposed: moment().format("HH:mm"),
      drug: "",
      discardReason: "",
      batchNumber: "",
      discardAmount: null,
      declaration: false,
      expiryDate: "",
    },
    authorisedBy: null,
    totalPrice: null,
    customPrescriber: "",
    sourceData: [
      {
        name: "Fresh Clinics",
        value: null,
      },
      {
        name: "Other",
        value: "other",
      },
    ],
    isLoading : false,
    isCustomProduct: false,
    customProductName: "",
    customProductUnit: "",
    filteredProducts: [],
    customProducts: [],
    organizationId: "",
    embedClinicId:"",
    customPrescribers: [],
    currentCustomPrescriber: "",
    isAddCustomPrescriber: false,
    isProductLoaded: false,
  }),
  methods: {
    ...mapActions("drugbook", ["discardDrug", "fetchCustomProducts", "getAmountPerClinic", "fetchCustomPrescribers"]),
    ...mapActions("product", ["fetchProducts"]),
    ...mapActions("clinic", ["fetchClinicsDetail"]),
    ...mapMutations("admin",["SET_BEARER"]),
    async getProductsList() {
      try {
        const res = await this.fetchProducts({
          limit: 1000,
        });
        this.drugItems = res.data.data.docs;
        if (this.drugItems.length > 0) {
          this.drugbookDisposalData.drug = this.drugData;
        }
      } catch (error) {
        console.error(error.message);
      }
    },
    async getCustomProducts() {
      try {
        const res = await this.fetchCustomProducts(this.organizationId);
        this.customProducts = res.data.data;
      } catch (error) {
        console.error("ERR: ", error);
      }
    },
    async getCustomPrescribers() {
      try {
        const res = await this.fetchCustomPrescribers(this.organizationId);
        this.customPrescribers = [
          {
            title: "",
            group: [
              ...res.data.data.map((item) => {
                return {
                  text: item.name,
                  value: item._id,
                };
              }),
            ],
          },
          {
            title: "Add custom prescriber",
            group: [],
          },
        ];
      } catch (error) {
        console.error("PRESCRIBERS ERR: ", error);
      }
    },
    async fetchAmountPerClinic(clinicID, isClinicChange = true) {
      try {
        const res = await this.getAmountPerClinic({ clinicId: clinicID });
        const customIds = this.customProducts.map(item => item._id);
        const clinicsWithAmount = _.pullAll(res.data.data
        .map((e) => {
            const id = e._id.product || e._id.custom
            if(!customIds.includes(id)){
              return {
                  text: this.drugItems.find((item) => item._id === id).name,
                  value: id
                };
            }
            return undefined
          }),[undefined])
          .sort((a, b) =>
            a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
          );
          this.drugDetail = [...this.drugItems, ...this.customProducts].filter(
            (e) => e._id === this.drugData
          )[0];
        if (clinicsWithAmount && this.drugItems.length > 0) {
          let temp = this.drugItems
            .filter((e) => {
              return !clinicsWithAmount.some((item) => item.value === e._id);
            })
            .map((e) => {
              return {
                text: e.name,
                value: e._id,
              };
            });
          this.filteredProducts = [
            {
              title: "Stocked Products",
              group: [...clinicsWithAmount],
            },
            {
              title: "My Products",
              group: this.customProducts.map((value) => {
                        return {
                          text: value.name,
                          value: value._id,
                        };
                    }).sort((a, b) => a.text && b.text && a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1),
            },
            {
              title: "All Products",
              group: [...temp].sort((a, b) => a.text && b.text && a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1),
            },
            {
              title: "Add new product",
              group: [],
            },
          ];
          this.isProductLoaded = true;
          if (this.filteredProducts[1] && this.filteredProducts[1].group.length === 0){
            this.filteredProducts.splice(1, 1);
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    addCustomProduct() {
      let closeSelect = this.$refs.disposeSelect;
      closeSelect.active = false;
      this.isCustomProduct = true;
      const arrLength = this.filteredProducts.length-1;
      this.filteredProducts[arrLength].group = [...this.filteredProducts[arrLength].group,
        {
          value: "add",
          text: "+ Add new Product",
        },
      ]
      this.drugbookDisposalData.drug = "add";
    },
    addCustomPrescriber() {
      let closeSelect = this.$refs.customPrescriber;
      if(closeSelect) closeSelect.active = false;
      this.isAddCustomPrescriber = true;
      this.customPrescribers[1].title = "";
      this.customPrescribers[1].group = [
        ...this.customPrescribers[1].group,
        {
          value: "custom",
          text: "+ Add Custom Prescriber",
        },
      ];
      this.currentCustomPrescriber = "custom";
    },
    async discardRecord() {
      let isValid = await this.$validator.validate();

      if (!this.drugbookDisposalData.declaration) {
        this.declarationChecker =
          "Please check this box if you want to proceed.";
      }
      if (isValid && !this.declarationChecker) {
        this.isLoading = true;
        let payload = {
          clinicId: this.clinic.clinicId,
          entryDate: moment(this.drugbookDisposalData.dateDisposed)
            .hour(
              moment(this.drugbookDisposalData.timeDisposed, "H:m").get("hour")
            )
            .minute(
              moment(this.drugbookDisposalData.timeDisposed, "H:m").get(
                "minute"
              )
            ),
          // productId: this.drugbookDisposalData.drug,
          amount: this.drugbookDisposalData.discardAmount.toFixed(2),
          declaration: this.drugbookDisposalData.declaration,
          performedBy: this.userId,
          discardReason: this.drugbookDisposalData.discardReason,
          batchNumber: this.drugbookDisposalData.batchNumber,
          ...(this.totalPrice && {otherPrescriberTotalAmount: this.totalPrice}),
          expiryDate: moment(this.drugbookDisposalData.batchExpiry),
        };

        let isFreshProduct = this.filteredProducts[1].group.some(e => e.value === this.drugbookDisposalData.drug)
        if(this.filteredProducts.length === 4 && isFreshProduct){
          payload = {
            ...payload,
            customProductId: this.drugbookDisposalData.drug,
          }
        } else {
          payload = {
            ...payload,
            productId: this.drugbookDisposalData.drug,
          }
        }

        if (this.isCustomProduct) {
          payload = {
            ...payload,
            productId: "",
            newCustomProduct: {
              organizationId: this.organizationId,
              name: this.customProductName,
              unit: this.customProductUnit,
            },
          };
        }

        if (this.authorisedBy) {
          if (this.currentCustomPrescriber !== 'custom'){
          payload = {
            ...payload,
            otherPrescriberId: this.currentCustomPrescriber,
          };
        } else {
          payload = {
            ...payload,
            newCustomPrescriber: {
              organizationId: this.organizationId,
              name: this.customPrescriber,
            },
          };
        }
        }
        try {
          const res = await this.discardDrug([payload]);
          if (res.status === 200) {
            this.$vs.notify({
              title: "Discard Drug",
              text: "Drug Successfully Discarded",
              color: "success",
            });
            this.clearForm();
            const tempProduct = res.data.data[0].productId || res.data.data[0].customProductId;
            this.$emit("changeSelectedDrug", tempProduct);
            this.$emit("fetchDrugEntries");
            this.$emit("fetchCustomProductss");
            this.$emit("cancelForm");
            this.$validator.reset();
          }
        } catch (error) {
          console.error(error.message);
        }
      }
      this.isLoading = false;
    },
    closeModal() {
      this.clearForm();
      this.$emit("cancelForm");
      this.isCustomProduct = false;
      this.authorisedBy = null;
      this.customPrescriber = "";
      this.customProductName = "";
      this.customProductUnit = "";
      this.$validator.reset();
    },
    clearForm() {
      this.drugbookDisposalData.dateDisposed = moment().format("L");
      this.drugbookDisposalData.timeDisposed = moment().format("HH:mm");
      this.drugbookDisposalData.drug = this.drugData;
      this.drugbookDisposalData.discardReason = "";
      this.drugbookDisposalData.batchNumber = "";
      this.drugbookDisposalData.discardAmount = null;
      this.drugbookDisposalData.declaration = false;
      this.drugbookDisposalData.expiryDate = "";
      // this.drugDetail = null;
      this.declarationChecker = "";
    },
    async fetchOrgIdByClinic(){
     const res = await this.fetchClinicsDetail(this.$route.query.clinicId)
          const clinicsDetail = res.data.data
          const orgId = clinicsDetail.organizationDetail._id
          return orgId;
    },
  },
  watch: {
    show: function (newVal, oldVal) {
      this.drugbookDisposalData.timeDisposed = moment().format("HH:mm");
    },
    "drugbookDisposalData.drug": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.drugDetail = [...this.drugItems, ...this.customProducts].filter((e) => e._id === newVal)[0];
      }
    },
    "drugbookDisposalData.declaration": {
      handler() {
        this.declarationChecker = "";
      },
    },
    drugData: {
      handler() {
        if (this.drugItems.length > 0) {
          this.drugbookDisposalData.drug = this.drugData;
          this.drugDetail = this.drugItems.filter(
            (e) => e._id === this.drugData
          )[0];
        }
      },
    },
    drugbookDisposalData: {
      handler(val) {
        if (val.drug !== "add") {
          this.isCustomProduct = false;
          this.customProductName = "";
          this.customProductUnit = "";
          const arrLength = this.filteredProducts.length-1;
          if (this.filteredProducts.length &&
            this.filteredProducts[arrLength] &&
            this.filteredProducts[arrLength].group.length &&
            this.filteredProducts[arrLength].group[this.filteredProducts[arrLength].group.length - 1].value === 'add'
          ){
            this.filteredProducts[arrLength].group.pop();
          }
        }
      },
      deep: true,
    },
    currentCustomPrescriber: {
      handler(val) {
        if (val !== "custom") {
          this.isAddCustomPrescriber = false;
          if (
            this.customPrescribers.length &&
            this.customPrescribers[1].group[
              this.customPrescribers[1].group.length - 1
            ].value === "custom"
          ) {
            this.customPrescribers[1].title = "Add Custom Prescriber";
            this.customPrescribers[1].group.pop();
          }
        }
      },
    },
  },
  computed: {
    formattedAmount: {
      get: function () {
        const d = Math.pow(10, 2);
        return (
          this.drugbookDisposalData.discardAmount &&
          Math.round(
            (this.drugbookDisposalData.discardAmount + Number.EPSILON) * d
          ) / d
        );
      },
      set: function (newValue) {
        this.drugbookDisposalData.discardAmount = newValue;
      },
    },
    customProductDeclaration() {
      if (this.drugbookDisposalData.drug === 'add'){
        return true
      }
      return false
    },
    isNZRegion() {
      return process.env.VUE_APP_REGION === "NZ"
    }
  },
  async created() {
    this.token = this.$route.query.token
    this.embedClinicId = this.$route.query.clinicId
    this.SET_BEARER(this.$route.query.token)
    if(this.$route.query.token && this.$route.query.token.length>0){
      this.userId = jwt_decode(this.$route.query.token).id;
    }
    else{
      const user = JSON.parse(
        localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
      );
      this.userId = user._id;
      this.userFullName = `${
        user.firstName
      } ${
        user.lastName
      }`;
    }
    if(this.$route.query.clinicId && this.$route.query.clinicId.length>0){
      this.organizationId = await this.fetchOrgIdByClinic()
      await this.getProductsList();
      await this.getCustomProducts();
    }
    else if (
      localStorage.getItem(
        `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
      )
    ) {
      this.organizationId = localStorage.getItem(
        `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
      );
      this.getProductsList();
      this.getCustomProducts();
    }
  },
  mounted() {
    setTimeout(() => {
      let clinic = this.selectedClinic ? this.selectedClinic : this.$route.query.selectedClinic;
      if (clinic) this.fetchAmountPerClinic(clinic);
      this.getCustomPrescribers();
    }, 1000);
  },
};
</script>

<style lang="scss">
  .vdp-datepicker__calendar {
    z-index: 99999 !important;
  }
  
  .popUpCustom {
    .con-vs-card {
      border: none !important;
    }
    .vs-card--content {
      max-height: 75vh;
      overflow: auto;
    }
  }

  .drugbookFields {
    .vs-input--input,
    .vs-input--placeholder,
    .vs-select--input,
    .vdp-datepicker input,
    input {
      font-family: inherit !important;
      font-size: 16px !important;
      padding: 0.8rem 0.8rem;
    }
  }

  .input-text .is-danger,
  .is-danger input {
    border: 1px solid rgba(var(--vs-danger),1)!important
  }
  .bg-color-error{
    background: rgba(var(--vs-danger), .2);
    color: rgba(var(--vs-danger), 1);
  }
</style>
<style scoped>
.customButton{
  bottom: -1.7vmax;
}
</style>
