<template>
  <vs-card>
    <div v-if="labelVisibility" slot="header">
      <h3>Add Treatment</h3>
      <span class="text-base text-green-dark font-medium">{{ this.clinic.name }}</span>
    </div>
    <form autocomplete="off" class="drugbookFields">
      <p class="text-sm mt-1">
        Add a Drugbook Record for treatments authorised outside of the Fresh Application
      </p>
      <div class="flex flex-col space-y-4 mt-6">
        <div class="w-full">
          <label class="text-md">Date added</label>
          <Datepicker
            format="dd MMMM yyyy"
            v-model="customDrugbookData.entryDate"
            :input-class="{
              'is-true':
                !errors.has('customDrugbookData.entryDate') &&
                customDrugbookData.entryDate,
              'is-danger': errors.has('customDrugbookData.entryDate'),
            }"
          >
            <template slot="afterDateInput">
              <span
                v-if="customDrugbookData.entryDate"
                class="bg-color-done input-icon-validate vs-input--icon-validate"
              >
                <i
                  valiconpack="material-icons"
                  class="vs-icon notranslate icon-scale material-icons null"
                  >done</i
                >
              </span>
              <span
                v-if="errors.has('customDrugbookData.entryDate')"
                class="bg-color-error input-icon-validate vs-input--icon-validate"
              >
                <i
                  valiconpack="material-icons"
                  class="vs-icon notranslate icon-scale material-icons null"
                  >error</i
                >
              </span>
            </template>
          </Datepicker>
        </div>

      <div class="w-full">
        <label class="text-md">Time added</label>
          <vs-input
            type="time"
            name="timeAdded"
            data-vv-as="Time Added"
            class="w-full"
            v-model="customDrugbookData.timeAdded"
          />
        </div>

        <div class="w-full">
          <label class="text-md">Patient Name</label>
          <vs-input
            :danger="errors.first('customDrugbookData.patientName') ? true : false"
            :danger-text="errors.first('customDrugbookData.patientName')"
            :success="
              !errors.first('customDrugbookData.patientName') &&
              customDrugbookData.patientName != ''
            "
            data-vv-scope="customDrugbookData"
            val-icon-success="done"
            val-icon-danger="error"
            v-validate="{
              required: true,
            }"
            data-vv-as="patientName"
            name="patientName"
            placeholder="Patient Name"
            v-model="customDrugbookData.patientName"
            class="w-full"
          />
        </div>

        <div class="w-full">
          <label class="text-md">Select Drug</label>
          <vs-select
            :disabled="!isProductLoaded"
            ref="productSelect"
            class="select relative"
            width="100%"
            placeholder="Select Drug"
            :danger="errors.first('productId') ? true : false"
            :success="
              !errors.first('productId') && customDrugbookData.productId !== ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            v-validate="{ required: true }"
            data-vv-as="productId"
            autocomplete
            name="productId"
            :danger-text="errors.first('productId')"
            v-model="customDrugbookData.productId"
          >
            <div :key="index" v-for="(item, index) in filteredProducts">
              <vs-select-group :title="item.title" v-if="item.group.length">
                <vs-select-item
                  :key="index"
                  :value="item.value"
                  :text="item.text"
                  v-for="(item, index) in item.group"
                />
              </vs-select-group>
            </div>
            <vs-button
              v-if="isProductLoaded"
              class="absolute w-full right-0 bottom-0 py-3 rounded-none z-10"
              :class="isCustomProduct ? 'hidden' : 'customButton'"
              color="#e1e8e6"
              text-color="#475c55"
              icon="add"
              @click="addCustomProduct"
            >
              Add new Product
            </vs-button>
          </vs-select>
        </div>

      <div
        v-if="customDrugbookData.productId === 'add'"
        class="w-full"
      >
        <vs-input
          :danger="errors.first('customProductName') ? true : false"
          :danger-text="errors.first('customProductName')"
          :success="
            !errors.first('customProductName') && customProductName != ''
          "
          val-icon-success="done"
          val-icon-danger="error"
          v-validate="'required'"
          data-vv-as="customProductName"
          name="customProductName"
          label="New Product Name"
          placeholder="New Product Name"
          v-model="customProductName"
          class="w-full"
        />
      </div>
      <div
        v-if="customDrugbookData.productId === 'add'"
        class="w-full"
      >
        <vs-input
          :danger="errors.first('customProductUnit') ? true : false"
          :danger-text="errors.first('customProductUnit')"
          :success="
            !errors.first('customProductUnit') && customProductUnit != ''
          "
          val-icon-success="done"
          val-icon-danger="error"
          v-validate="'required'"
          data-vv-as="customProductUnit"
          name="customProductUnit"
          label="Units of Measurement"
          placeholder="Units of Measurement"
          v-model="customProductUnit"
          class="w-full"
        />
      </div>
      <div class="w-full">
        <vs-input
          :danger="errors.first('customDrugbookData.amount') ? true : false"
          :danger-text="errors.first('customDrugbookData.amount')"
          :success="
            !errors.first('customDrugbookData.amount') &&
            customDrugbookData.amount != ''
          "
          data-vv-scope="customDrugbookData"
          val-icon-success="done"
          val-icon-danger="error"
          v-validate="{
            required: true,
            min_value: 0.1,
          }"
          data-vv-as="amount"
          name="amount"
          label="Amount Administered"
          placeholder="Amount Administered"
          v-model.number="formattedAmount"
          class="w-full"
        />
      </div>
      <div class="w-full">
        <vs-select
          :disabled="isNZRegion"
          autocomplete
          label="Source"
          class="select"
          width="100%"
          name="authorisedBy"
          v-model="authorisedBy"
        >
          <vs-select-item
            :key="index"
            :value="item.value"
            :text="item.name"
            v-for="(item, index) in sourceData"
          />
        </vs-select>
      </div>

    <!-- <div v-if="authorisedBy === 'other'" class="w-full fieldContainer">
      <div class="w-full">
        <vs-input
          data-vv-as="totalPrice"
          name="totalPrice"
          label="Total Price (Optional)"
          placeholder="Total Price"
          v-model.number="totalPrice"
          class="w-full"
        />
      </div>
    </div> -->
      <div class="w-full" v-if="authorisedBy === 'other'">
        <label for="prescriber" class="text-md"> Prescriber </label>
          <vs-select
            ref="customPrescriber"
            class="select"
            width="100%"
            name="currentCustomPrescriber"
            autocomplete
            v-model="currentCustomPrescriber"
            v-validate="'required'"
            data-vv-as="currentCustomPrescriber"
            :danger="errors.first('currentCustomPrescriber') ? true : false"
            :danger-text="errors.first('currentCustomPrescriber')"
            :success="!errors.first('currentCustomPrescriber') && currentCustomPrescriber != ''"
            val-icon-success="done"
            val-icon-danger="error"
            :class="{'is-danger': errors.has('currentCustomPrescriber')}"
          >
            <div :key="index" v-for="(item, index) in customPrescribers">
              <vs-select-group :title="item.title" v-if="item.group">
                <vs-select-item
                  :key="index"
                  :value="item.value"
                  :text="item.text"
                  v-for="(item, index) in item.group"
                />
              </vs-select-group>
            </div>
            <vs-button
              class="absolute w-full right-0 py-2 rounded-t-none rounded-b-sm justify-start pl-3 z-10"
              :class="isAddCustomPrescriber ? 'hidden' : 'bottom-0'"
              color="#e1e8e6"
              text-color="#475c55"
              icon="add"
              @click="addCustomPrescriber"
            >
              Add Custom Prescriber
            </vs-button>
            <!-- <vs-select-item
              :key="index"
              :value="item.value"
              :text="item.name"
              v-for="(item, index) in customPrescribers"
            /> -->
          </vs-select>
        <span
          v-if="error.customPrescriber"
          class="con-text-validation span-text-validation-danger vs-input--text-validation-span ml-1"
          >Please choose or add a custom prescriber</span
        >
      </div>

      <div
        class="w-full"
        v-if="currentCustomPrescriber === 'custom'"
      >
          <vs-input
            label="Authorising Dr"
            placeholder="Authorising Dr"
            name="customPrescriber"
            class="w-full"
            v-model="customPrescriber"
            v-validate="'required'"
            data-vv-as="customPrescriber"
            :danger="errors.first('customPrescriber') ? true : false"
            :danger-text="errors.first('customPrescriber')"
            :success="!errors.first('customPrescriber') && customPrescriber != ''"
            val-icon-success="done"
            val-icon-danger="error"
          />
      </div>

      <div class="w-full">
        <label class="text-md">Notes (Optional)</label>
          <vs-input
            name="notes"
            autocomplete
            placeholder="Add notes (Optional)"
            class="w-full"
            v-model="customDrugbookData.notes"
            data-vv-as="notes"
            data-vv-scope="customDrugbookData"
            :danger="errors.first('customDrugbookData.notes') ? true : false"
            :danger-text="errors.first('customDrugbookData.notes')"
            :success="!errors.first('customDrugbookData.notes') && customDrugbookData.notes != ''"
            val-icon-success="done"
            val-icon-danger="error"
          />
        </div>

      <div class="w-full">
        <label class="text-md">Batch Number</label>
          <vs-input
            name="batchNumber"
            class="w-full"
            v-model="customDrugbookData.batchNumber"
            v-validate="'required'"
            data-vv-as="batchNumber"
            data-vv-scope="customDrugbookData"
            :danger="errors.first('customDrugbookData.batchNumber') ? true : false"
            :danger-text="errors.first('customDrugbookData.batchNumber')"
            :success="!errors.first('customDrugbookData.batchNumber') && customDrugbookData.batchNumber != ''"
            val-icon-success="done"
            val-icon-danger="error"
          />
        </div>

      <div class="w-full">
        <label class="text-md">Batch Expiry</label>
          <Datepicker
            format="dd MMMM yyyy"
            :typeable="true"
            name="expiryDate"
            data-vv-as="expiryDate"
            v-model="customDrugbookData.expiryDate"
            v-validate="'required'"
            data-vv-scope="customDrugbookData"
            :danger="errors.first('customDrugbookData.expiryDate') ? true : false"
            :danger-text="errors.first('customDrugbookData.expiryDate')"
            :success="!errors.first('customDrugbookData.expiryDate') && customDrugbookData.expiryDate != ''"
            val-icon-success="done"
            val-icon-danger="error"
            class="input-text"
            :input-class="{
              'is-true': !errors.has('customDrugbookData.expiryDate') && customDrugbookData.expiryDate,
              'is-danger': errors.has('customDrugbookData.expiryDate'),
            }"
          >
          </Datepicker>
          <small class="text-danger">
            {{ errors.first('customDrugbookData.expiryDate') }}
          </small>
        </div>
      </div>
      <div class="flex items-center justify-between space-x-4 mt-5">
        <vs-button type="border" class="w-full flex-1 p-3" @click="onCancel">
          Cancel
        </vs-button>
        <vs-button type="border" class="bg-primary hover:bg-primary text-white w-full flex-1 p-3" @click="onSave" :disabled="isLoading">Save</vs-button>
      </div>
    </form>
  </vs-card>
</template>

<script>
import _ from "lodash";
import Datepicker from "vuejs-datepicker";
import { mapActions, mapMutations } from "vuex";
import { Validator } from "vee-validate";
import moment from "moment";
import jwt_decode from 'jwt-decode';

const dict = {
  custom: {
    amount: {
      required: "Please enter amount",
      min_value: "Please enter a valid amount",
    },
    entryDate: {
      required: "Please enter date",
    },
    productId: {
      required: "Please select drug",
    },
    timeAdded: {
      required: "Please enter time",
    },
    patientName: {
      required: "Please enter patient name"
    },
    notes: {
      required: "Notes is required"
    },
    batchNumber: {
      required: "Batch number is required"
    },
    expiryDate: {
      required: "Batch expiry is required"
    }
  },
};

Validator.localize("en", dict);
export default {
  props: [
    "cancelForm",
    "selectedClinic",
    "selectedDrug",
    "clinic",
    "isMobile",
    "show",
    "fetchCustomProductss",
    "labelVisibility",
  ],
  components: {
    Datepicker,
  },
  data: () => ({
    userId: "",
    discardTypes: [],
    batchNumbers: [],
    drugItems: [],
    organizationId: "",
    customDrugbookData: {
      clinicId: "",
      entryDate: moment().format("L"),
      patientName: "",
      amount: null,
      productId: null,
      batchNumber: "",
      timeAdded: moment().format("HH:mm"),
      expiryDate: "",
      notes: "",
    },
    authorisedBy: null,
    totalPrice: null,
    customPrescriber: "",
    sourceData: [
      {
        name: "Fresh Clinics",
        value: null,
      },
      {
        name: "Other",
        value: "other",
      },
    ],
    isCustomProduct: false,
    customProductName: "",
    customProductUnit: "",
    filteredProducts: [],
    customProducts: [],
    customPrescribers: [],
    currentCustomPrescriber: "",
    isAddCustomPrescriber: false,
    error: {
      customPrescriber: false,
    },
    isProductLoaded: false,
    isLoading: false,
  }),
  methods: {
    ...mapActions("drugbook", [
      "addStockReceivedOne",
      "getAmountPerClinic",
      "fetchCustomProducts",
      "fetchCustomPrescribers",
      "addCustomRecord",
    ]),
    ...mapActions("clinic", ["fetchClinicsDetail"]),
    ...mapMutations("admin",["SET_BEARER"]),
    ...mapActions("product", ["fetchProducts"]),
    async getProductsList() {
      try {
        const res = await this.fetchProducts({
          limit: 1000,
        });
        this.drugItems = res.data.data.docs;
        if (this.drugItems.length > 0) {
          this.drugDetail = this.drugItems.filter(
            (e) => e._id === this.selectedDrug
          )[0];
        }
      } catch (error) {
        console.error(error.message);
      }
    },
    async getCustomProducts() {
      try {
        const res = await this.fetchCustomProducts(this.organizationId);
        this.customProducts = res.data.data;
      } catch (error) {
        console.error("ERR: ", error);
      }
    },
    async getCustomPrescribers() {
      try {
        const res = await this.fetchCustomPrescribers(this.organizationId);
        this.customPrescribers = [
          {
            title: "",
            group: [
              ...res.data.data.map((item) => {
                return {
                  text: item.name,
                  value: item._id,
                };
              }),
            ],
          },
          {
            title: "Add custom prescriber",
            group: [],
          },
        ];
      } catch (error) {
        console.error("PRESCRIBERS ERR: ", error);
      }
    },
    async fetchAmountPerClinic(clinicID, isClinicChange = true) {
      try {
        const res = await this.getAmountPerClinic({ clinicId: clinicID });
        const customIds = this.customProducts.map(item => item._id);
        const clinicsWithAmount = _.pullAll(res.data.data
        .map((e) => {
            const id = e._id.product || e._id.custom
            if(!customIds.includes(id)){
              return {
                  text: this.drugItems.find((item) => item._id === id).name,
                  value: id
                };
            }
            return undefined
          }),[undefined])
          .sort((a, b) =>
            a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
          );
        if (clinicsWithAmount && this.drugItems.length > 0) {
          let temp = this.drugItems
            .filter((e) => {
              return !clinicsWithAmount.some((item) => item.value === e._id);
            })
            .map((e) => {
              return {
                text: e.name,
                value: e._id,
              };
            });

          this.filteredProducts = [
            {
              title: "Stocked Products",
              group: [...clinicsWithAmount],
            },
            {
              title: "My Products",
              group: this.customProducts.map((value) => {
                        return {
                          text: value.name,
                          value: value._id,
                        };
                    }).sort((a, b) => a.text && b.text && a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1),
            },
            {
              title: "All Products",
              group: [...temp].sort((a, b) => a.text && b.text && a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1),
            },
            {
              title: "Add new product",
              group: [],
            },
          ];
          this.isProductLoaded = true;
          if (this.filteredProducts[1] && this.filteredProducts[1].group.length === 0) {
            this.filteredProducts.splice(1, 1);
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    onCancel() {
      this.cancelForm();
      this.isCustomProduct = false;
      this.authorisedBy = null;
      this.customPrescriber = "";
      this.customProductName = "";
      this.customProductUnit = "";
      this.clearForm();
      this.$validator.reset();
    },
    addCustomProduct() {
      let closeSelect = this.$refs.productSelect;
      if(closeSelect) closeSelect.active = false;
      this.isCustomProduct = true;
      const arrLength = this.filteredProducts.length-1;
      this.filteredProducts[arrLength].group = [...this.filteredProducts[arrLength].group,
        {
          value: "add",
          text: "+ Add new Product",
        },
      ]
      this.customDrugbookData.productId = "add";
    },
    addCustomPrescriber() {
      let closeSelect = this.$refs.customPrescriber;
      if(closeSelect) closeSelect.active = false;
      this.isAddCustomPrescriber = true;
      this.customPrescribers[1].title = "";
      this.customPrescribers[1].group = [
        ...this.customPrescribers[1].group,
        {
          value: "custom",
          text: "+ Add Custom Prescriber",
        },
      ];
      this.currentCustomPrescriber = "custom";
    },
    clearForm() {
      this.customDrugbookData.entryDate = moment().format("L");
      this.customDrugbookData.amount = null;
      this.customDrugbookData.batchNumber = "";
      this.customDrugbookData.timeAdded = moment().format("HH:mm");
      this.customDrugbookData.expiryDate = "";
      this.customDrugbookData.notes = "";
      this.customDrugbookData.productId = this.selectedDrug;
      this.customDrugbookData.patientName = "";
    },
    async onSave() {
      let isValid = await this.$validator.validate();

      let data = {
        clinicId: this.$route.query.selectedClinic,
        entryDate: moment(this.customDrugbookData.entryDate)
          .hour(moment(this.customDrugbookData.timeAdded, "H:m").get("hour"))
          .minute(
            moment(this.customDrugbookData.timeAdded, "H:m").get("minute")
          ),
        amount: this.customDrugbookData.amount.toFixed(2),
        performedBy: this.userId,
        batchNumber: this.customDrugbookData.batchNumber,
        notes: this.customDrugbookData.notes,
        type: "used",
        patientName: this.customDrugbookData.patientName,
        ...(this.totalPrice && {otherPrescriberTotalAmount: this.totalPrice}),
      };

      let isCustom = this.filteredProducts[1].group.some(
        (e) => e.value === this.customDrugbookData.productId
      );

      if (this.filteredProducts.length === 4 && isCustom) {
        data = {
          ...data,
          customProductId: this.customDrugbookData.productId,
        };
      } else {
        data = {
          ...data,
          productId: this.customDrugbookData.productId,
        };
      }

      if (this.isCustomProduct) {
        data = {
          ...data,
          newCustomProduct: {
            organizationId: this.organizationId,
            name: this.customProductName,
            unit: this.customProductUnit,
          },
        };
        delete data.productId;
      }

      if (this.authorisedBy) {
        if (this.currentCustomPrescriber !== 'custom'){
          data = {
            ...data,
            otherPrescriberId: this.currentCustomPrescriber,
          };
        } else {
          data = {
            ...data,
            newCustomPrescriber: {
              organizationId: this.organizationId,
              name: this.customPrescriber,
            },
          };
        }
      }

      if (this.currentCustomPrescriber || this.authorisedBy === null) {
        this.error.customPrescriber = false;
      } else {
        this.error.customPrescriber = true;
      }

      if (this.customDrugbookData.expiryDate !== "") {
        data["expiryDate"] = moment(this.customDrugbookData.expiryDate).format(
          "YYYY-MM-DD"
        );
      }

      if (isValid && !this.error.customPrescriber) {
        this.isLoading = true;
        try {
          const res = await this.addCustomRecord({drugEntries: [data]});
          if (res.status === 200) {
            this.cancelForm();
            this.isCustomProduct = false;
            this.$vs.notify({
              title: "Added new drugbook record",
              text: "New drugbook record successfully created.",
              color: "success",
            });
            this.clearForm();
            const tempProduct = res.data.data[0].productId || res.data.data[0].customProductId;
            this.$emit("changeSelectedDrug", tempProduct);
            this.$emit("fetchDrugEntries");
            this.$emit("fetchCustomProductss");
            this.$validator.reset();
          } else {
            this.$vs.notify({
              title: "Failed",
              text: "Failed to add new Drugbook record. Try again!",
              color: "danger",
            });
          }
          this.isLoading = false;
        } catch (e) {
          this.isLoading = false;
          console.error("ERRRRRR: ",e);
          this.$vs.notify({
            title: "Failed",
            text: "Failed to add new Drugbook record. Try again!",
            color: "danger",
          });
        }
      }
    },
    async fetchOrgIdByClinic(){
     const res = await this.fetchClinicsDetail(this.$route.query.clinicId)
          const clinicsDetail = res.data.data
          const orgId = clinicsDetail.organizationDetail._id
          return orgId;
    },
  },
  watch: {
    'customDrugbookData.productId': {
      handler(val) {
        if (val !== "add") {
          this.isCustomProduct = false;
          this.customProductName = "";
          this.customProductUnit = "";
          const arrLength = this.filteredProducts.length-1;
          if (this.filteredProducts.length &&
            this.filteredProducts[arrLength] &&
            this.filteredProducts[arrLength].group.length &&
            this.filteredProducts[arrLength].group[this.filteredProducts[arrLength].group.length - 1].value === 'add'
          ){
            this.filteredProducts[arrLength].group.pop();
          }
        }
      },
    },
    "$route.query.selectedClinic": function (newVal, oldVal) {
      if (newVal) this.fetchAmountPerClinic(newVal);
    },
    show: function (newVal, oldVal) {
      this.customDrugbookData.timeAdded = moment().format("HH:mm");
    },
    currentCustomPrescriber: {
      handler(val) {
        if (val) this.error.customPrescriber = false;
        if (val !== "custom") {
          this.isAddCustomPrescriber = false;
          if (
            this.customPrescribers.length &&
            this.customPrescribers[1].group[
              this.customPrescribers[1].group.length - 1
            ].value === "custom"
          ) {
            this.customPrescribers[1].title = "Add Custom Prescriber";
            this.customPrescribers[1].group.pop();
          }
        }
      },
    },
    selectedDrug:  {
      handler() {
        if (this.drugItems.length > 0) {
          this.drugDetail = this.drugItems.filter(
            (e) => e._id === this.selectedDrug
          )[0];
        }
      }
    },
  },
  computed: {
    formattedAmount: {
      get: function () {
        const d = Math.pow(10, 2);
        return (
          this.customDrugbookData.amount &&
          Math.round((this.customDrugbookData.amount + Number.EPSILON) * d) / d
        );
      },
      set: function (newValue) {
        this.customDrugbookData.amount = newValue;
      },
    },
    isNZRegion() {
      return process.env.VUE_APP_REGION === "NZ"
    }
  },
  async created() {
    this.token = this.$route.query.token
    this.embedClinicId = this.$route.query.clinicId
    this.SET_BEARER(this.$route.query.token)
    if(this.$route.query.token && this.$route.query.token.length>0){
      this.userId = jwt_decode(this.$route.query.token).id;
    }
    else{
      this.userId = JSON.parse(
        localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
      )._id;
    }
     if(this.$route.query.clinicId && this.$route.query.clinicId.length>0){
      this.organizationId = await this.fetchOrgIdByClinic()
      await this.getProductsList();
      await this.getCustomProducts();
      this.customDrugbookData.productId = this.selectedDrug;
    }
    else if (
      localStorage.getItem(
        `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
      )
    ) {
      this.organizationId = localStorage.getItem(
        `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
      );
    }
    this.getProductsList();
    this.customDrugbookData.productId = this.selectedDrug;
    this.getCustomProducts();
  },
  mounted() {
    setTimeout(() => {
      let clinic = this.selectedClinic ? this.selectedClinic : this.$route.query.selectedClinic;
      if (clinic) this.fetchAmountPerClinic(clinic);
      this.getCustomPrescribers();
    }, 1000);
  }
};
</script>

<style lang="scss">
  .vdp-datepicker__calendar {
    z-index: 99999 !important;
  }

  .popUpCustom {
    .con-vs-card {
      border: none !important;
    }
    .vs-card--content {
      max-height: 75vh;
      overflow: auto;
    }
  }

  .drugbookFields {
    .vs-input--input,
    .vs-input--placeholder,
    .vs-select--input,
    .vdp-datepicker input,
    input {
      font-family: inherit !important;
      font-size: 16px !important;
      padding: 0.8rem 0.8rem;
    }
  }

  .input-text .is-danger,
  .is-danger input {
    border: 1px solid rgba(var(--vs-danger),1)!important
  }
  .bg-color-error{
    background: rgba(var(--vs-danger), .2);
    color: rgba(var(--vs-danger), 1);
  }
</style>

<style scoped>
  .customButton{
    bottom: -1.7vmax;
  }
</style>
