import { render, staticRenderFns } from "./addCustomRecord.vue?vue&type=template&id=e5cd3aae&scoped=true&"
import script from "./addCustomRecord.vue?vue&type=script&lang=js&"
export * from "./addCustomRecord.vue?vue&type=script&lang=js&"
import style0 from "./addCustomRecord.vue?vue&type=style&index=0&id=e5cd3aae&prod&lang=scss&"
import style1 from "./addCustomRecord.vue?vue&type=style&index=1&id=e5cd3aae&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e5cd3aae",
  null
  
)

export default component.exports